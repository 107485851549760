.com_contact {
    .content-img {
        margin-right: 35px;

        img {
            height: 96px;
            top: -22px;
            position: relative;

            @media screen and (max-width: $breakPointDesktop) {
                height: 48px;
                top: -11px;
            }
        }
        @media screen and (max-width: $breakPointDesktop) {
            margin-right: 18px;
        }
    }

    .inner-content {
        display: flex;
    }

    .rowTime {
        margin-top: 200px;

        @media screen and (max-width: $breakPointDesktop) {
            margin-top: 100px;
        }
    }

    .contact-infos {
        span {
            display: inline-block;
        }

        .jicons-text {
            position: relative;
            padding-left: 60px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                height: 40px;
                background-image: url(../images/icons/whatsapp.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }

            @media screen and (max-width: $breakPointDesktop) {
                padding-left: 30px;

                &:before {
                    width: 20px;
                    height: 20px;
                }
            }

        }

        .phone-contact {
            margin-bottom: $lineHeightText;

            .jicons-text:before {
                background-image: url(../images/icons/phone.svg);
            }
        }
    }
}