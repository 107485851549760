.page-blog {
    .items-leading {
        .grid {
            margin: 0 -50px;

            &__item {
                padding: 0 50px;
            }

            @media screen and (max-width: $breakPointDesktop) {
                margin: 0 -25px;
                
                &__item {
                    padding: 0 25px;
                }
            }
        }
        
        .item {
            box-shadow: 0px 12px 64.78px 14.22px rgba(52, 98, 91, 0.08);
            border-radius: 40px;
            padding: 50px;

            @media screen and (max-width: $breakPointDesktop) {
                border-radius: 20px;
                padding: 25px;
            }

            

            .readmore {
                display: none;
            }
        }
    }

    .search {
        margin-bottom: $lineHeightTextL;
        position: relative;

        #mod-search-searchword {
            border-radius: 0;
            border-left: none;
            border-top: none;
            border-right: none;
            text-align: left;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
    
            &+.btn-clear {
                display: none;
            }
        }

        .btn-submit {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 54px;
            height: 54px;
            border: none;
            background-color: transparent;
            background-image: url('../images/icons/search.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 54px auto;
            outline: none;

            @media screen and (max-width: $breakPointDesktop) {
                width: 27px;
                height: 27px;
                background-size: 27px auto;
            }
        }
    }

    .tags {
        margin: 77px -10px 0;

        @media screen and (max-width: $breakPointDesktop) {
            margin: 36px -10px 0;
        }

        li {
            background-color: rgba(55, 206, 157, 0.11);
            padding: 16px 26px;
            border-radius: 30px;
            display: inline-block;
            margin: 0 10px 15px;

            &:before {
                display: none;
            }

            @media screen and (max-width: $breakPointDesktop) {
                padding: 8px 13px;
                border-radius: 15px;
            }
        }
    }
    

    .tagspopular {
        ul {
            li {
                padding-left: 28px;
                margin-bottom: $lineHeightText;

                &:before {
                    background-image: url(../images/icons/flesh-Btn.svg);
                    transform: rotate(180deg);
                    background-size: 16px auto;
                    width: 16px;
                    height: 16px;
                    bottom: 0;
                    margin: auto;
                }

                @media screen and (max-width: $breakPointDesktop) {
                    padding-left: 14px;
                    
                    &:before {
                        background-size: 8px auto;
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }
}