.credit_page {
    .services {
        .col-lg-10 {
            .row {
                &:not(:last-of-type) {
                    margin-bottom: 120px;

                    @media screen and (max-width: $breakPointDesktop) {
                        margin-bottom: 60px;
                    }
                }

                .col-lg-9 {
                    text-align: center;
                }
            }
        }
    }

    .box-processus {
        h3 {
            margin-bottom: 0;
        }
    }

    .steps {
        .step {
            display: flex;
            align-items: end;
            min-height: 227.4px;
            position: relative;

            .step_num {
                display: inline-block;
                font-family: $font2;
                font-weight: 700;
                font-size: 227.4px;
                line-height: 0.5;
                color: #38756b;
            }

            .step_text {
                display: inline-block;
                line-height: 1.113;
                max-width: 210px;
                margin-left: 26px;
            }

            &:after {
                content: "";
                position: absolute;
                right: 30px;
                bottom: 30px;
                margin: auto;
                border: 3px solid #38756b;
                border-left: none;
                border-top: none;
                width: 50px;
                height: 50px;
                transform: rotate(-45deg);
            }

            @media screen and (max-width: $breakPointDesktop) {
                min-height: 115px;

                .step_num {
                    font-size: 115px;
                }

                .step_text {
                    max-width: 120px;
                    margin-left: 13px;
                }

                &:after {
                    right: 15px;
                    bottom: 15px;
                    border-width: 1.5px;
                    width: 25px;
                    height: 25px;
                }
            }

            &:nth-of-type(1) {
                .step_num {
                    color: #38756b;
                }

                &:after {
                    border-color: #38756b;
                }
            }
            &:nth-of-type(2) {
                .step_num {
                    color: #1f926b;
                }
                &:after {
                    border-color: #1f926b;
                }
            }
            &:nth-of-type(3) {
                .step_num {
                    color: #3db58c;
                }
                &:after {
                    border-color: #3db58c;
                }
            }
            &:nth-of-type(4) {
                .step_num {
                    color: #2ae0a8;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    .section.section-text-full {


        .col-lg-4 h4 {
            line-height: 1.35;
        }

        .col-auto {
            h3 {
                text-align: center;
                margin-bottom: 43px;
                
                @media screen and (max-width: $breakPointDesktop) {
                    margin-bottom: 21px;
                }

                + * {
                    text-align: center;
                }
            }
        }
    }
}